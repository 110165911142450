.root {
}

.description {
  color: gray;
  font-size: smaller;
  font-weight: 600;

  margin-top: 5px;
  margin-bottom: 8px;
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
