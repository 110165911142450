@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.swiperContainer {
  padding-bottom: 20px;
  @media (--viewportMedium) {
    padding-bottom: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}


/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.endOfSlideContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    height: 60%;
  }
}

.endOfSlideButton {
  composes: buttonFont buttonText buttonColors from global;
  padding: 1rem;
  width: 40%;
  aspect-ratio: 1/1;

  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.outsideButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-inline: 2rem;

  margin-top: 2rem;
}
