@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (--viewportLarge) {
    flex-direction: row;
    margin-top: 33px;
    align-items: stretch;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-width: 80vw;

  & > p {
    margin: 5px;
  }

  @media (--viewportLarge) {
    max-width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & > p {
      font-size: 1rem;
    }
  }

  @media (--viewportLargeWithPaddings) {
    & > p {
      font-size: 1.2rem;
    }
  }

  @media (--viewportXLarge) {
    & > p {
      font-size: 1.5rem;
    }
  }
}

.swiperContainer {
  max-width: 85vw;
  margin-bottom: 1rem !important;

  @media (--viewportXSmall) {
    max-width: 90vw;
  }
  
  @media (--viewportLarge) {
    max-width: 50vw;
    margin: auto;
  }
}

.locations {
  display: flex;
  margin-top: 0px;

  @media (--viewportLarge) {
    flex-direction: row;
    margin-top: 33px;
    width: 45vw;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:first-of-type {
  @media (--viewportMedium) {
    margin-left: 10px;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  min-width: 100px;
  min-height: 200px;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }

  @media (--viewportMedium) {
    min-height: 300px;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.endOfSlideContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    height: 60%;
  }
}

.endOfSlideButton {
  composes: buttonFont buttonText buttonColors from global;
  padding: 1rem;
  width: 40%;
  aspect-ratio: 1/1;

  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.outsideButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-inline: 2rem;

  margin-top: 2rem;
}
