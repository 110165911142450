@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.label {
  margin-bottom: 0px;
  font-weight: 550;
}

.description {
  color: gray;
  font-size: smaller;
  font-weight: 600;

  margin-top: 5px;
  margin-bottom: 8px;
}

.addFAQButton {
  border: none;
  text-decoration: underline;
  margin-bottom: 12px;

  display: inline-flex;
  align-items: center;
  margin-left: auto;

  color: var(--marketplaceColor);

  cursor: pointer;

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.removeFAQButton {
  border: none;
  text-decoration: underline;

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    padding: 1rem;
    cursor: pointer;
  }
}

.faqContainer {
  composes: formMargins;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  @media (--viewportMedium) {
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;
  }
}

.faq {
  width: 100%;
}

.title {
  composes: formMargins;
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
