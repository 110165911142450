@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  padding: 15px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom: 2px solid var(--marketplaceColor);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}
