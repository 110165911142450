@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.outsideButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-inline: 2rem;

  margin-top: 2rem;
}