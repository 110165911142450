@import '../../styles/customMediaQueries.css';

.button {
  display: inline-block !important;

  border: none;
  cursor: pointer;

  transition: 100ms;
  scale: 150%;

  height: fit-content;

  color: var(--marketplaceColor);

  &:hover {
    scale: 200%;
  }
}

.container {
  display: flex !important;
  align-items: center;
  flex-direction: row;
  max-width: 90vw;

  @media (--viewportLarge) {
    max-width: 80vw;
  }
}
