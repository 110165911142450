@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.title {
  composes: h1 from global;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportLarge) {
    justify-content: center;
    gap: 2rem;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepImage {
  width: 100%;
  display: flex;
  justify-content: center;
}

.stepImage img {
  max-width: 200px;

  @media (--viewportMedium) {
    max-width: 120px;
  }

  @media (--viewportLarge) {
    max-width: 150px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
