.accordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--attentionColorLight);
}

.accordionTitle:hover {
  background-color: var(--failColorLight);
}

.accordionTitle,
.accordionContent {
  padding: 1rem;
}

.titleContent {
  composes: marketplaceBodyFontStyles from global;
  margin: 0;
}