.container {
  justify-content: center;
  display: flex;
  justify-content: center;
}

.toggleSwitch {
  position: relative;
  width: 50px;
  display: inline-block;
  text-align: left;
}

.toggleLabel {
  margin: 0 8px 0 0;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid var(--matterColorAnti);
  border-radius: 20px;
  padding: 0;
  line-height: 24px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  /* line-height: 24px; */
  color: var(--matterColorLight);
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: '';
  /* padding-left: 10px; */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.inner:after {
  content: '';
  /* padding-right: 10px; */
  background-color: var(--matterColorAnti);
  color: var(--matterColorLight);
  text-align: right;
}

.switch {
  display: block;
  width: 20px;
  margin: 4px;
  background: var(--matterColorLight);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 20px;
  right: 20px;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked + .label .inner {
  margin-left: 0;
}

.checkbox:checked + .label .switch {
  right: 0px;
}
