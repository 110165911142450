@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.swiperContainer {
  margin-top: 1rem;
  margin-bottom: 1rem !important;
}

.swiperSlide {
  @media (--viewportMedium) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.locations {
  display: flex;
  margin-top: 0px;
  width: 100%;
  justify-content: center;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;
  padding: 1rem;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 10px;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
  
  @media (--viewportMedium) {
    height: auto;
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.endOfSlideContainer {
  display: flex !important;
  justify-content: center;
  align-items: center;

  width: 100% !important;
  height: 200px !important;

  @media (--viewportLarge) {
    height: 270px !important;
  }

  @media (--viewportLargeWithPaddings) {
    height: 290px !important;
  }

  @media (--viewportCustomXLarge) {
    height: 340px !important;
  }

  @media (--viewportXLarge) {
    height: 400px !important;
  }
}

.endOfSlideButton {
  composes: buttonFont buttonText buttonColors from global;
  padding: 1rem;
  width: 50%;
  aspect-ratio: 1/1;

  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.outsideButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  padding-inline: 2rem;

  margin-top: 2rem;
}
